import React from 'react';
import Link from 'next/link';

const Highlight = require('react-highlighter');

import {
  Grid,
  Typography,
  colorPalette,
  LIcon,
  Chip,
  Tooltip,
  Avatar,
  masks,
} from '@l_ine/core';

import * as styled from './index.style';

import {
  lineExclamationTriangle,
  lineLock,
  linePhone,
  lineBoxUSD,
} from '@l_ine/solid-svg-icons';
import { lineAddressBook } from '@l_ine/light-svg-icons';
import {
  lineTimes,
  lineUser,
  lineEnvelope,
  lineDollarSignCircle,
} from '@l_ine/regular-svg-icons';
import TooltipDateContent from '../../TooltipDateContent';

import { PartnerListPayload } from '../../../redux/users/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { assembleArrayEmail, assembleArrayFone } from '../../utility/assembleArrays';
import RedirectLink from 'components/redirectLink/redirectLink';

const ListContent: React.FC<
  PartnerListPayload & { index: number; search: string }
> = ({
  clientesDoParceiro,
  codigo,
  codigoParceiro,
  cpfCnpj,
  dataBloqueio,
  dataContratacao,
  dataInativacao,
  dataInclusao,
  diasCliente,
  diasDesdeUltimoLogin,
  email,
  email2,
  email3,
  email4,
  email5,
  emailResponsavel,
  endCidade,
  endUf,
  fone1,
  fone2,
  fone3,
  fone4,
  fone5,
  foneResponsavel,
  motivoBloqueio,
  motivoInativacao,
  nomeFantasia,
  nomeParceiro,
  nomeResponsavel,
  pendente,
  planoNome,
  planoValor,
  razaoSocial,
  situacao,
  testeGratis,
  tipo,
  tipoPessoa,
  vinculado,
  search,
  index,
}) => {
  const fonesArray = assembleArrayFone({
    foneResponsavel,
    fone1,
    fone2,
    fone3,
    fone4,
    fone5,
  });
  const emailsArray = assembleArrayEmail({
    emailResponsavel,
    email,
    email2,
    email3,
    email4,
    email5,
  });

  const highlighted = (text: string) => {
    return <Highlight search={search}>{text}</Highlight>;
  };

  return (
    <Grid container spacing={1} style={{ display: 'flex', flexDirection: 'row' }}>
      <Grid item xs={12}>
        <RedirectLink to={`/clientes/perfil?id=${codigo}`}>
          {/* <a
            style={{
              textDecoration: 'none',
              zIndex: 30,
            }}
            href={`/clientes/perfil?id=${codigo}`}
          > */}
          <Grid
            container
            spacing={1}
            style={{
              padding: '0.5em',
              color:
                situacao == 0
                  ? colorPalette.neutral[100]
                  : colorPalette.neutral[900],
              display: 'flex',
            }}
          >
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip label={situacao == 1 ? '' : 'Cliente inativo'}>
                <span>
                  <Avatar
                    data-testid={`clientes--listItem[${index}]--avatar`}
                    size='md'
                    status={situacao == 0 ? 'offline' : undefined}
                    badgeProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                    }}
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bolder',
                      color: colorPalette.neutral[0],
                      backgroundColor:
                        situacao == 0
                          ? colorPalette.neutral[50]
                          : colorPalette.neutral[100],
                      position: 'relative',
                      zIndex: 20,
                    }}
                  >
                    {nomeFantasia ? nomeFantasia : razaoSocial}
                  </Avatar>
                </span>
              </Tooltip>
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '1.1em',
                }}
              >
                <Grid item xs={12} sm={7}>
                  <Grid container>
                    {((nomeFantasia &&
                      razaoSocial &&
                      nomeFantasia.toLowerCase() != razaoSocial.toLowerCase()) ||
                      tipo == 'Parceiro') && (
                      <Grid item xs={12} style={{ marginBottom: '7px' }}>
                        <styled.IconTextWrapper>
                          {tipo == 'Parceiro' && (
                            <Tooltip label='Cliente é um parceiro'>
                              <span>
                                <Chip
                                  label='Parceiro'
                                  color={colorPalette.neutral[100]}
                                  style={{
                                    color: colorPalette.neutral[0],
                                    position: 'relative',
                                    zIndex: 20,
                                  }}
                                  size='sm'
                                  data-testid={`clientes--listItem[${index}]--chip-parceiro`}
                                />
                              </span>
                            </Tooltip>
                          )}
                          {nomeFantasia &&
                            razaoSocial &&
                            nomeFantasia.toLowerCase() !=
                              razaoSocial.toLowerCase() && (
                              <Typography
                                variant='titleXS'
                                style={{
                                  marginLeft: tipo == 'Parceiro' ? '0.3em' : 0,
                                }}
                              >
                                {nomeFantasia.toLowerCase()}
                              </Typography>
                            )}
                        </styled.IconTextWrapper>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <styled.IconTextWrapper>
                        <Typography
                          variant='titleXS'
                          style={{ textTransform: 'capitalize' }}
                        >
                          {highlighted(codigo.toString())}
                          {' - '}
                          {razaoSocial
                            ? highlighted(razaoSocial.toUpperCase())
                            : highlighted(nomeFantasia.toUpperCase())}
                        </Typography>
                      </styled.IconTextWrapper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {testeGratis == 1 && (
                    <Tooltip
                      label=' '
                      placement='left'
                      description={
                        <TooltipDateContent
                          {...{
                            dataInclusao,
                            dataContratacao,
                            dataBloqueio,
                            dataInativacao,
                            motivoBloqueio,
                            motivoInativacao,
                          }}
                        />
                      }
                    >
                      <span>
                        <Chip
                          icon={lineDollarSignCircle}
                          data-testid={`clientes--listItem[${index}]--chip-testeGratis`}
                          label={
                            <Typography variant='bodyXS'>Teste grátis</Typography>
                          }
                          size='sm'
                          color={colorPalette.neutral[30]}
                          style={{
                            position: 'relative',
                            zIndex: 20,
                            marginLeft: '8px',
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}

                  {situacao == 0 && (
                    <Tooltip
                      label=' '
                      placement='left'
                      description={
                        <TooltipDateContent
                          {...{
                            dataInclusao,
                            dataContratacao,
                            dataBloqueio,
                            dataInativacao,
                            motivoBloqueio,
                            motivoInativacao,
                          }}
                        />
                      }
                    >
                      <span>
                        <Chip
                          data-testid={`clientes--listItem[${index}]--chip-Inativo`}
                          label={<Typography variant='bodyXS'>Inativo</Typography>}
                          size='sm'
                          color={colorPalette.neutral[100]}
                          style={{
                            color: colorPalette.neutral[0],
                            marginLeft: '8px',
                          }}
                          icon={lineTimes}
                        />
                      </span>
                    </Tooltip>
                  )}
                  {pendente == 1 && (
                    <Chip
                      data-testid={`clientes--listItem[${index}]--chip-atraso`}
                      label={<Typography variant='bodyXS'>Em atraso</Typography>}
                      size='sm'
                      color={colorPalette.yellow[300]}
                      icon={lineExclamationTriangle}
                      style={{
                        marginLeft: '8px',
                      }}
                    />
                  )}
                  {situacao == 2 && (
                    <Tooltip
                      label=' '
                      placement='left'
                      description={
                        <TooltipDateContent
                          {...{
                            dataInclusao,
                            dataContratacao,
                            dataBloqueio,
                            dataInativacao,
                            motivoBloqueio,
                            motivoInativacao,
                          }}
                        />
                      }
                    >
                      <span>
                        <Chip
                          data-testid={`clientes--listItem[${index}]--chip-bloqueado`}
                          label={<Typography variant='bodyXS'>Bloqueado</Typography>}
                          size='sm'
                          color={colorPalette.red[300]}
                          style={{
                            color: colorPalette.neutral[0],
                            marginLeft: '8px',
                          }}
                          icon={lineLock}
                        />
                      </span>
                    </Tooltip>
                  )}
                </Grid>

                <Grid item xs={12} md={6} style={{ margin: '0.3em 0 0.3em 0' }}>
                  <Grid container>
                    <Grid item xs={12} style={{ marginBottom: '0.3em' }}>
                      <Typography
                        variant='bodyXS'
                        style={{
                          position: 'relative',
                          display: 'flex',
                          zIndex: 99,
                        }}
                      >
                        {highlighted(endCidade && `${endCidade} - ${endUf} `)}
                        &nbsp;
                        {endCidade && cpfCnpj && '|'}&nbsp;
                        <CopyToClipboard text={`${masks.cpfCnpj.format(cpfCnpj)}`}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'copy',
                              zIndex: 99,
                            }}
                          >
                            {cpfCnpj && `${tipoPessoa === 1 ? ' CNPJ' : ' CPF'}: `}
                            &nbsp;
                            {cpfCnpj && masks.cpfCnpj.format(cpfCnpj)}
                          </div>
                        </CopyToClipboard>
                      </Typography>
                    </Grid>
                    {nomeResponsavel && (
                      <Grid item xs={12} style={{ marginBottom: '0.3em' }}>
                        <styled.IconTextWrapper>
                          <LIcon icon={lineAddressBook} size={'18px'} removeMargin />
                          <Tooltip label='Contato do cliente'>
                            <span>
                              <Typography
                                variant='bodyXS'
                                style={{
                                  position: 'relative',
                                  zIndex: 20,
                                  marginLeft: '0.2em',
                                }}
                              >
                                {highlighted(nomeResponsavel)}
                              </Typography>
                            </span>
                          </Tooltip>
                        </styled.IconTextWrapper>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={4}>
                      {fonesArray.length > 0 ? (
                        <>
                          {fonesArray.map((phone) => (
                            <styled.IconTextWrapper>
                              <LIcon icon={linePhone} size={'18px'} removeMargin />
                              <Typography
                                variant='bodyXS'
                                style={{
                                  color:
                                    situacao != 0
                                      ? colorPalette.neutral[900]
                                      : colorPalette.neutral[100],
                                  marginLeft: '0.2em',
                                }}
                              >
                                {highlighted(masks.phone.format(phone))}
                              </Typography>
                            </styled.IconTextWrapper>
                          ))}
                        </>
                      ) : (
                        <styled.IconTextWrapper>
                          <LIcon icon={linePhone} size={'18px'} removeMargin />
                          <Typography
                            variant='bodyXS'
                            style={{
                              color:
                                situacao != 0
                                  ? colorPalette.neutral[900]
                                  : colorPalette.neutral[100],
                              marginLeft: '0.2em',
                            }}
                          >
                            {'Não cadastrado'}
                          </Typography>
                        </styled.IconTextWrapper>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={8}>
                      {emailsArray.length > 0 ? (
                        <>
                          {emailsArray.map((email) => (
                            <styled.IconTextWrapper>
                              <LIcon
                                icon={lineEnvelope}
                                size={'18px'}
                                removeMargin
                              />
                              <Typography
                                variant='bodyXS'
                                style={{
                                  color:
                                    situacao != 0
                                      ? colorPalette.neutral[900]
                                      : colorPalette.neutral[100],
                                  marginLeft: '0.2em',
                                }}
                              >
                                {highlighted(email)}
                              </Typography>
                            </styled.IconTextWrapper>
                          ))}
                        </>
                      ) : (
                        <styled.IconTextWrapper>
                          <LIcon icon={lineEnvelope} size={'18px'} removeMargin />
                          <Typography
                            variant='bodyXS'
                            style={{
                              color:
                                situacao != 0
                                  ? colorPalette.neutral[900]
                                  : colorPalette.neutral[100],
                              marginLeft: '0.2em',
                            }}
                          >
                            {'Não cadastrado'}
                          </Typography>
                        </styled.IconTextWrapper>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} style={{ margin: '0.3em 0 0.3em 0' }}>
                  <Grid container>
                    <Grid item xs={12} style={{ marginBottom: '0.2em' }}>
                      <Tooltip
                        label=' '
                        placement='left'
                        description={
                          <TooltipDateContent
                            {...{
                              dataInclusao,
                              dataContratacao,
                              dataBloqueio,
                              dataInativacao,
                              motivoBloqueio,
                              motivoInativacao,
                            }}
                          />
                        }
                      >
                        <span>
                          <Typography
                            variant='bodyXS'
                            color={colorPalette.neutral[100]}
                            style={{ position: 'relative', zIndex: 20 }}
                          >
                            {testeGratis == 0 &&
                              (diasCliente > 0
                                ? `Conosco há ${diasCliente} ${
                                    diasCliente == 1 ? 'dia' : 'dias'
                                  }`
                                : 'Conosco desde hoje')}

                            {testeGratis == 1 &&
                              (diasCliente > 0
                                ? `Em teste grátis há ${diasCliente} ${
                                    diasCliente == 1 ? 'dia' : 'dias'
                                  }`
                                : 'Em teste grátis desde hoje')}

                            {diasDesdeUltimoLogin !== null &&
                              (diasDesdeUltimoLogin == 0
                                ? ` | Último login hoje`
                                : diasDesdeUltimoLogin === 1
                                ? ` | Último login ontem`
                                : ` | Último login há ${diasDesdeUltimoLogin} dias`)}
                          </Typography>
                        </span>
                      </Tooltip>
                    </Grid>

                    {nomeParceiro && (
                      <Grid item xs={12} style={{ marginBottom: '0.2em' }}>
                        <styled.IconTextWrapper>
                          <LIcon icon={lineUser} size={'18px'} removeMargin />
                          <Typography variant='bodyXS'>
                            {nomeParceiro &&
                              vinculado == 0 &&
                              `Indicado de ${nomeParceiro}`}
                          </Typography>
                          <Typography variant='bodyXS'>
                            {nomeParceiro && vinculado == 1 && `Vinculado à`}
                            &nbsp;
                          </Typography>
                          {nomeParceiro && vinculado == 1 && (
                            <Link
                              href={
                                window.location.href.includes('/perfil')
                                  ? `${window.location.href.replace(
                                      String(codigo),
                                      String(codigoParceiro)
                                    )}`
                                  : `${window.location.href}/perfil?id=${codigoParceiro}`
                              }
                              data-testid={`clientes--listItem[${index}]--link-parceiroVinculado`}
                            >
                              <a
                                href={
                                  window.location.href.includes('/perfil')
                                    ? `${window.location.href.replace(
                                        String(codigo),
                                        String(codigoParceiro)
                                      )}`
                                    : `${window.location.href}/perfil?id=${codigoParceiro}`
                                }
                                style={{
                                  zIndex: 25,
                                  textDecoration: 'none',
                                  color: colorPalette.neutral[900],
                                }}
                              >
                                <styled.PartnerLink>
                                  <Typography
                                    variant='bodyXS'
                                    color={
                                      situacao == 0
                                        ? colorPalette.neutral[100]
                                        : colorPalette.neutral[900]
                                    }
                                  >
                                    {nomeParceiro}
                                  </Typography>
                                </styled.PartnerLink>
                              </a>
                            </Link>
                          )}
                        </styled.IconTextWrapper>
                      </Grid>
                    )}

                    {clientesDoParceiro > 0 && (
                      <Grid item xs={12} style={{ marginBottom: '0.2em' }}>
                        <styled.IconTextWrapper>
                          <LIcon icon={lineUser} size={'18px'} removeMargin />
                          <Typography variant='bodyXS'>
                            {`Possui ${clientesDoParceiro} ${
                              clientesDoParceiro > 1
                                ? 'clientes ativos'
                                : 'cliente ativo'
                            }`}
                          </Typography>
                        </styled.IconTextWrapper>
                      </Grid>
                    )}

                    <Grid item xs={12} style={{ marginBottom: '0.2em' }}>
                      <styled.IconTextWrapper>
                        <LIcon
                          icon={lineBoxUSD}
                          size='18px'
                          style={{
                            position: 'relative',
                          }}
                          removeMargin
                        />
                        <Typography
                          variant='bodyXS'
                          style={{
                            position: 'absolute',
                            zIndex: 90,
                            marginLeft: '2em',
                          }}
                        >
                          {`${planoNome} (${masks.valor.format(planoValor)}/mês)`}
                        </Typography>
                      </styled.IconTextWrapper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </a> */}
        </RedirectLink>
      </Grid>
    </Grid>
  );
};

export default ListContent;
