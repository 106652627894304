import React from 'react';

import { colorPalette, Grid, LIcon, Typography, Divider } from '@l_ine/core';

import * as styled from './index.style';

import { lineCommentLines, lineStar } from '@l_ine/solid-svg-icons';
import { ExtraContentProps, ExtraFilters } from './index.d';

import { formatWith0 } from '../../utility/masks';

const ExtraContent: React.FC<ExtraContentProps> = ({
  comentarios,
  detratores,
  indicacao,
  naoVotantes,
  neutros,
  promotores,
  total,
  itemType,
  filters,
  setFilters,
}) => {
  const handleFilter = (tipo: string, filtro?: any) => {
    if (tipo == 'tipo') {
      delete filters.indicacao;
      itemType != 'comentario' && delete filters.comentario;
      setFilters({
        ...filters,
        page: 1,
        tipo: filtro == filters.tipo ? 'all' : filtro,
      });
    } else if (tipo == 'indicacao') {
      delete filters.comentario;
      if (filters.indicacao) {
        delete filters.indicacao;
        setFilters((current: ExtraFilters) => ({ ...current, page: 1 }));
      } else {
        setFilters({
          ...filters,
          page: 1,
          indicacao: true,
        });
      }
    } else if (tipo == 'comentario') {
      delete filters.indicacao;
      if (filters.comentario) {
        delete filters.comentario;
        setFilters((current: ExtraFilters) => ({ ...current, page: 1 }));
      } else {
        setFilters({
          ...filters,
          page: 1,
          comentario: true,
        });
      }
    }
  };

  const handleTotalTitle = React.useCallback((itemType: string, total: number) => {
    switch (itemType) {
      case 'promotor':
        return total != 1 ? 'Promotores' : 'Promotor';
      case 'detrator':
        return total != 1 ? 'Detratores' : 'Detrator';
      case 'neutro':
        return total != 1 ? 'Neutros' : 'Neutro';
      case 'all':
        return total != 1 ? 'Respostas' : 'Resposta';
      case 'comentarios':
        return total != 1 ? 'Comentários' : 'Comentário';
      case 'indicacao':
        return total != 1 ? 'Indicações' : 'Indicação';
      default:
        return 'Resultados';
    }
  }, []);

  return (
    <div>
      <Grid container style={{ marginTop: '1em', marginBottom: '0.8em' }}>
        <Divider color={colorPalette.neutral[30]} orientation='horizontal' />
      </Grid>
      <Grid container>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                margin: '0.5em',
                marginLeft: '0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                {total ? formatWith0(total) : 0}
              </Typography>
              <Typography
                variant='bodyXS'
                color={colorPalette.neutral[600]}
                style={{ textTransform: 'capitalize' }}
              >
                &nbsp;{handleTotalTitle(itemType, total)}
              </Typography>
            </div>
            {(itemType == 'all' || itemType == 'comentario') && (
              <>
                <div
                  style={{
                    marginLeft: '0.5em',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleFilter('tipo', 'promotor')}
                  data-testid='resultados--nps--modal-nps--list--button-promotor'
                >
                  <styled.Ellipse color={colorPalette.green[300]} />
                  <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                    {promotores ? promotores : 0}
                  </Typography>
                  <Typography
                    variant={filters.tipo == 'promotor' ? 'titleXXS' : 'bodyXS'}
                    color={colorPalette.neutral[600]}
                  >
                    &nbsp;Promotores
                  </Typography>
                </div>
                <div
                  style={{
                    marginLeft: '0.5em',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleFilter('tipo', 'neutro')}
                  data-testid='resultados--nps--modal-nps--list--button-neutro'
                >
                  <styled.Ellipse color={colorPalette.neutral[100]} />
                  <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                    {neutros ? neutros : 0}
                  </Typography>
                  <Typography
                    variant={filters.tipo == 'neutro' ? 'titleXXS' : 'bodyXS'}
                    color={colorPalette.neutral[600]}
                  >
                    &nbsp;Neutros
                  </Typography>
                </div>
                <div
                  style={{
                    marginLeft: '0.5em',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleFilter('tipo', 'detrator')}
                  data-testid='resultados--nps--modal-nps--list--button-detrator'
                >
                  <styled.Ellipse color={colorPalette.red[300]} />
                  <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                    {detratores ? detratores : 0}
                  </Typography>
                  <Typography
                    variant={filters.tipo == 'detrator' ? 'titleXXS' : 'bodyXS'}
                    color={colorPalette.neutral[600]}
                  >
                    &nbsp;Detratores
                  </Typography>
                </div>
              </>
            )}
          </div>
        </Grid>
        <Grid item sm={8} xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {(itemType == 'promotor' || itemType == 'all') && (
              <div
                onClick={() => handleFilter('indicacao')}
                style={{
                  margin: '0.5em',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                data-testid='resultados--nps--modal-nps--list--button-indicacao'
              >
                <LIcon
                  icon={lineStar}
                  size='18px'
                  color={colorPalette.neutral[100]}
                />
                <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                  {indicacao ? indicacao : 0}
                </Typography>
                <Typography
                  variant={filters.indicacao ? 'titleXXS' : 'bodyXS'}
                  color={colorPalette.neutral[600]}
                >
                  &nbsp;Indicações
                </Typography>
              </div>
            )}
            {itemType != 'comentario' && (
              <div
                onClick={() => handleFilter('comentario')}
                style={{
                  margin: '0.5em',
                  marginRight: '0',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                data-testid='resultados--nps--modal-nps--list--button-comentario'
              >
                <LIcon
                  icon={lineCommentLines}
                  size='18px'
                  color={colorPalette.neutral[100]}
                />
                <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                  {comentarios ? comentarios : 0}
                </Typography>
                <Typography
                  variant={filters.comentario ? 'titleXXS' : 'bodyXS'}
                  color={colorPalette.neutral[600]}
                >
                  &nbsp;Comentários
                </Typography>
              </div>
            )}
            {itemType == 'all' && (
              <div
                style={{
                  margin: '0.5em',
                  marginRight: '0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                  {naoVotantes ? naoVotantes : 0}
                </Typography>
                <Typography variant='bodyXS' color={colorPalette.neutral[600]}>
                  &nbsp;Não respondidas
                </Typography>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExtraContent;
